import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { WalletProvider } from "./contexts/WalletProvider";
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import Landing from "./pages/Landing/Landing";
import Memes from "./pages/Memes/Memes";
import Careers from "./pages/Careers/Careers";
import "./App.css";

function App() {
  return (
    <WalletProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/memes" element={<Memes />} />
        </Routes>
      </Router>
    </WalletProvider>
  );
}

export default App;
