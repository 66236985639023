import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import "./Memes.css";
import Footer from "../../components/Footer";
import memes from "../../constants/Memes"; // Import the memes array
import characters from "../../constants/Templates"; // Import the templates array
import branding from "../../constants/Branding"; // Import the branding array

const memeCategories = {
  memes: "just pepe",
  characters: "characters",
  branding: "branding",
};

const Memes = () => {
  const [selectedCategory, setSelectedCategory] = useState("memes");
  const [filteredMemes, setFilteredMemes] = useState([]);
  const [displayedMemes, setDisplayedMemes] = useState([]);
  const [selectedMeme, setSelectedMeme] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const memesPerPage = 24;

  useEffect(() => {
    filterMemes();
    setCurrentPage(1); // Reset to first page when category changes
  }, [selectedCategory]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * memesPerPage;
    const endIndex = startIndex + memesPerPage;
    setDisplayedMemes(filteredMemes.slice(startIndex, endIndex));
  }, [currentPage, filteredMemes]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedMeme) {
        if (event.key === "ArrowRight") {
          navigateMeme("next");
        } else if (event.key === "ArrowLeft") {
          navigateMeme("previous");
        }
      } else {
        if (event.key === "ArrowRight") {
          goToPage(Math.min(currentPage + 1, totalPages));
        } else if (event.key === "ArrowLeft") {
          goToPage(Math.max(currentPage - 1, 1));
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedMeme, currentPage, totalPages]);

  const filterMemes = () => {
    let filtered;
    if (selectedCategory === "characters") {
      filtered = characters;
    } else if (selectedCategory === "branding") {
      filtered = branding;
    } else {
      filtered = memes.filter((meme) =>
        meme.category.includes(memeCategories[selectedCategory])
      );
    }

    // Sort the memes so that the latest memes display first
    filtered.sort((a, b) => b.id - a.id);

    setFilteredMemes(filtered);
    const totalPages = Math.ceil(filtered.length / memesPerPage);
    setTotalPages(totalPages);
    const startIndex = 0;
    const endIndex = memesPerPage;
    setDisplayedMemes(filtered.slice(startIndex, endIndex));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleMemeClick = (meme) => {
    setSelectedMeme(meme);
  };

  const closeModal = () => {
    setSelectedMeme(null);
  };

  const handleDownloadClick = (id) => {
    const url =
      selectedCategory === "characters"
        ? `https://storage.googleapis.com/pepeonblast/png-templates/${id}.png`
        : selectedCategory === "branding"
        ? `https://storage.googleapis.com/pepeonblast/png-branding/${id}.png`
        : `https://storage.googleapis.com/pepeonblast/png-memes/pepeonblast_${id}.png`;
    window.open(url, "_blank");
  };

  const navigateMeme = (direction) => {
    if (selectedMeme) {
      const currentIndex = filteredMemes.findIndex(
        (meme) => meme.id === selectedMeme.id
      );
      let newIndex;
      if (direction === "next") {
        newIndex = (currentIndex + 1) % filteredMemes.length;
      } else if (direction === "previous") {
        newIndex =
          (currentIndex - 1 + filteredMemes.length) % filteredMemes.length;
      }
      setSelectedMeme(filteredMemes[newIndex]);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert(`Copied to clipboard: ${text}`);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top when page changes
  };

  const renderPagination = () => {
    const pageButtons = [];

    // Always show the first page
    if (currentPage > 3) {
      pageButtons.push(
        <button
          key={1}
          className={`pagination-button ${currentPage === 1 ? "selected" : ""}`}
          onClick={() => goToPage(1)}
        >
          1
        </button>
      );
      if (currentPage > 4) {
        pageButtons.push(
          <span key="start-ellipsis" className="pagination-ellipsis">
            ...
          </span>
        );
      }
    }

    // Determine start and end pages
    const startPage = Math.max(currentPage - 2, 2);
    const endPage = Math.min(currentPage + 2, totalPages - 1);

    // Add page buttons
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`pagination-button ${currentPage === i ? "selected" : ""}`}
          onClick={() => goToPage(i)}
        >
          {i}
        </button>
      );
    }

    // Ellipsis before last page
    if (currentPage < totalPages - 3) {
      pageButtons.push(
        <span key="end-ellipsis" className="pagination-ellipsis">
          ...
        </span>
      );
    }

    // Always show the last page
    if (currentPage < totalPages - 2) {
      pageButtons.push(
        <button
          key={totalPages}
          className={`pagination-button ${
            currentPage === totalPages ? "selected" : ""
          }`}
          onClick={() => goToPage(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return (
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pageButtons}
        <button
          className="pagination-button"
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="hall-of-memes">
      <div className="memes-title" id="memes-title">
        <span>pepe</span>
        <span className="default-font">&nbsp;</span>
        <span>on</span>
        <span className="default-font">&nbsp;</span>
        <span>blast</span>
        <span className="default-font">&nbsp;</span>
        <span>memeland</span>
      </div>
      <div className="controls">
        {Object.keys(memeCategories).map((category) => (
          <button
            key={category}
            className={`filter-button ${
              selectedCategory === category ? "selected" : ""
            }`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className={`meme-grid meme-grid-${selectedCategory}`}>
        {displayedMemes.map((meme, index) => (
          <div
            key={meme.id}
            className="meme-card"
            onClick={() => handleMemeClick(meme)}
          >
            <img src={meme.front} alt={`Meme ${meme.id}`} />
            <div className="meme-number">{meme.id}</div>
          </div>
        ))}
      </div>
      {renderPagination()}
      {selectedMeme && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-images">
              <img src={selectedMeme.front} alt="Meme" />
              <div
                className="download-button"
                onClick={() => handleDownloadClick(selectedMeme.id)}
              ></div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <div className="branding-footer">
        <div
          className="branding-footer-element"
          onClick={() => copyToClipboard("#ffbb01")}
        >
          Main Color: <span className="main-color">#ffbb01</span>
        </div>
        <div
          className="branding-footer-element"
          onClick={() => copyToClipboard("#ffe9ae")}
        >
          Secondary Color: <span className="secondary-color">#ffe9ae</span>
        </div>
        <div className="branding-footer-element">
          <a
            href="https://www.dafont.com/kg-happy.font"
            target="_blank"
            rel="noopener noreferrer"
          >
            Main Font: <span className="main-color">KGHappy</span>
          </a>
        </div>
        <div className="branding-footer-element">
          <a
            href="https://www.dafont.com/kg-happy.font"
            target="_blank"
            rel="noopener noreferrer"
          >
            Secondary Font: <span className="main-color">KGHappySolid</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Memes;
